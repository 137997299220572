import React from 'react';
import { StoreProvider } from './src/store';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/roboto-serif/400.css';
import '@fontsource/roboto-serif/400.css';

import './src/styles/tailwind.css';
import './src/styles/global.css';
import './src/styles/tooltip.css';
import 'react-modern-drawer/dist/index.css';

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);
