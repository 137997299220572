import React, { useState, useMemo, createContext, useContext } from 'react';

const useStoreController = () => {
  const [closeInfoBar, setCloseInfoBar] = useState(false);

  return {
    closeInfoBar,
    setCloseInfoBar,
  };
};

const StoreContext = createContext<ReturnType<typeof useStoreController>>({
  closeInfoBar: false,
  setCloseInfoBar: () => {},
});

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={useStoreController()}>
    {children}
  </StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);
